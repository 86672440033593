<template>
  <div>
    <vs-collapse>
      <vs-collapse-item :key="key" v-for="(item, key) in histories">
        <div slot="header" class="vx-row">
          <div class="w-full">
            <p class="font-bold" style="font-size: 0.83em !important">
              {{ $utils.dateToDDMMYYYYHHmm(item.CreateDate) }} -
              {{ item.Observation }}
            </p>
            <p class="" style="font-size: 0.75em !important">
              Criado por: {{ item.CreateByPerson.Name }}
            </p>
          </div>
        </div>
        <div v-if="item.changeParse.length > 0">
          <div v-if="(item.Archives || []).length > 0" class="text-right mb-5">
            <vs-button
              class=""
              @click="
                selectedArquivos = item.Archives;
                arquivosModal = true;
              "
              color="primary"
            >
              <span class="font-semibold pr-2 pl-2">
                Visualizar arquivo(s)
              </span>
            </vs-button>
          </div>
          <vs-table
            class="change_values"
            :hoverFlat="true"
            noDataText="Nenhuma alteração disponível"
            :data="item.changeParse"
          >
            <template slot="thead">
              <vs-th>Campo alterado</vs-th>
              <vs-th>Valor anterior</vs-th>
              <vs-th>Novo valor</vs-th>
            </template>
            <template slot-scope="{ data }" v-if="item">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ tr.Property }}</vs-td>
                <vs-td>{{ formatPropertyValue(tr.Type, tr.OldValue) }}</vs-td>
                <vs-td>{{ formatPropertyValue(tr.Type, tr.NewValue) }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div v-else class="vx-row">
          <div class="w-1/2">
            <p>Nenhuma alteração disponível</p>
          </div>
          <div v-if="(item.Archives || []).length > 0" class="w-1/2 text-right">
            <vs-button
              class="mr-5"
              @click="
                selectedArquivos = item.Archives;
                arquivosModal = true;
              "
              color="primary"
            >
              <span class="font-semibold pr-2 pl-2">
                Visualizar arquivo(s)
              </span>
            </vs-button>
          </div>
        </div>
      </vs-collapse-item>
    </vs-collapse>

    <listar-arquivos-popup
      :arquivosModal="arquivosModal"
      :arquivos="selectedArquivos"
      @fecharArquivosModal="
        arquivosModal = false;
        selectedArquivos = [];
      "
    />
  </div>
</template>

<script>
// import * as Enums from "@/utils/enums";
const PolicyStatus = Object.freeze([
  { Id: 1, Name: "Aguardando Emissão" },
  { Id: 2, Name: "Emitida" },
  { Id: 3, Name: "Cancelada" },
  { Id: 4, Name: "Vencida" }
]);
import ListarArquivosPopup from "./ListarArquivosPopup.vue";

export default {
  name: "historico-apolice",
  components: {
    ListarArquivosPopup
  },
  props: {
    history: { type: Array, required: true }
  },
  data() {
    return {
      arquivosModal: false,
      selectedArquivos: [],
      histories: []
    };
  },
  mounted() {
    this.history.map(history => {
      let parseChangedValues = {};
      if (history.ChangedValues) {
        parseChangedValues = JSON.parse(history.ChangedValues);
      }
      this.histories.push({
        ...history,
        changeParse: parseChangedValues.length ? parseChangedValues : []
      });
    });
  },
  methods: {
    formatPropertyValue(propertyType, value) {
      switch (propertyType) {
        case "DateTime":
          return value ? this.$utils.dateToDDMMYYYYHHmm(value) : "-";
        case "PolicyStatus":
          return PolicyStatus.find(x => x.Id == value).Name;
        default:
          return value ? value : "-";
      }
    }
  }
};
</script>
