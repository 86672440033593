var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            id: "listar-arquivos-modal",
            title: "",
            active: _vm.showArquivosModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.showArquivosModal = $event
            },
          },
        },
        [
          _c("h2", { staticClass: "text-primary" }, [_vm._v("Arquivos")]),
          _c(
            "vs-table",
            {
              staticClass: "table-list mt-5",
              attrs: {
                maxHeight: "600px",
                data: _vm.arquivos,
                maxItems: _vm.arquivos.length,
                hoverFlat: true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (item, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: item } },
                        [
                          _c("vs-td", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.Description) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.formatTypeName(
                                    item.PolicyHistoryArchiveTypeId
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$utils.dateToDDMMYYYYHHmm(item.CreateDate)
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { staticClass: "text-center" }, [
                            _c(
                              "span",
                              { staticClass: "flex" },
                              [
                                _c("vs-button", {
                                  attrs: {
                                    radius: "",
                                    color: "primary",
                                    type: "flat",
                                    icon: "get_app",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadArchive(
                                        item.ArchiveUniqueId
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("\n          Descrição\n        "),
                  ]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("\n          Tipo\n        "),
                  ]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("\n          Data\n        "),
                  ]),
                  _c("vs-th"),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }