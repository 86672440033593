var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "detalhe-apolice" }, [
    _c(
      "div",
      { staticClass: "m-1 mr-4 px-0 mb-5" },
      [
        _c("breadcrumb", {
          staticClass: "mb-6",
          attrs: {
            title: "Apólice",
            actualPage: "Detalhes da apólice",
            previousPage: "apolices",
            previousPageTitle: "Lista de apólices",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "detalhe-apolice new-tabs",
        staticStyle: { position: "relative", "margin-top": "80px" },
      },
      [
        _vm.policy.PolicyStatusId == 2 &&
        _vm.PerfilHelpers.isInsurance() &&
        _vm.PerfilHelpers.checkPermission([
          _vm.PerfilHelpers.menuFuncionalitiesEnum.BLOQUEIO_APOLICE_RENOVACAO,
        ])
          ? _c(
              "div",
              { class: _vm.policy.IsBlockRenewal ? "blocked" : "unblock" },
              [
                _vm._m(0),
                _c("div", { staticClass: "text" }, [
                  _c("b", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.policy.IsBlockRenewal
                            ? "Renovação  bloqueada "
                            : "Renovação Ativa"
                        )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.policy.IsBlockRenewal
                            ? "Renovação automática da apólice está bloqueada."
                            : "Renovação automática da apólice está Ativa."
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "button ml-3" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "5px 17px" },
                        attrs: { id: "blocked", round: "" },
                        on: {
                          click: function ($event) {
                            return _vm.disclamerdesabilitarRenovacao(_vm.policy)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.policy.IsBlockRenewal
                                ? "Desbloquear"
                                : "Bloquear"
                            )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.policy.PolicyUniqueId
          ? _c(
              "vs-tabs",
              { attrs: { color: "warning" } },
              [
                _c(
                  "vs-tab",
                  { attrs: { label: "Dados gerais" } },
                  [_c("detalhar-apolice", { attrs: { policy: _vm.policy } })],
                  1
                ),
                _c(
                  "vs-tab",
                  { attrs: { label: "Histórico" } },
                  [
                    _c("historico-apolice", {
                      attrs: { history: _vm.policy.PolicyHistory },
                    }),
                  ],
                  1
                ),
                _vm.policy.HasEndorsement
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Endosso(s)" } },
                      [
                        _c("endosso-list", {
                          attrs: { policyUniqueId: _vm.policy.PolicyUniqueId },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "el-icon-warning-outline" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }